import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CreateOrderDto } from '../models/dto/create-order.dto';
import { ResourceService } from 'src/app/core/services/resource.service';
import { Order } from 'src/app/core/models/order';
import { PaymentStatus } from 'src/app/core/models/enum/payment-status.enum';

@Injectable({
  providedIn: 'root',
})
export class OrderService extends ResourceService<Order> {
  constructor(http: HttpClient) {
    super(http, 'order');
  }

  createOrder(dto: CreateOrderDto): Observable<Order> {
    return this.http.post<any>(`${ this.baseUrl }/order`, dto);
  }

  getPaymentStatus(id: string): Observable<{ status: PaymentStatus }> {
    return this.http.get<{ status: PaymentStatus }>(`${ this.baseUrl }/order/${ id }/payment-status`);
  }
}
