import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckoutFormComponent } from './checkout-form/checkout-form.component';
import { MbWayComponent } from 'src/app/features/checkout/countdown/mb-way.component';

const routes: Routes = [
  { path: '', component: CheckoutFormComponent },
  { path: 'mbway', component: MbWayComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CheckoutRoutingModule {
}
