<div class="card mt-3 min-h-full">
  <p-messages severity="info">
    <ng-template pTemplate>
      <span>Após efetuada a compra, os produtos ficam disponíveis na aba <b>Os Meus Produtos</b></span>
    </ng-template>
  </p-messages>
  <div class="grid grid-nogutter align-items-start justify-content-center">
    <div class="col-12 px-4 mt-4 md:mt-6 md:px-6">
      <span class="text-900 block font-bold text-xl">Checkout</span>
    </div>
    <div class="col-12 lg:col-6 h-full px-4 py-4 md:px-6" *ngIf="(cart$ | async)?.length != 0">
      <form [formGroup]="form" class="grid formgrid flex-column gap-4">
        <span class="text-900 text-2xl block font-medium mb-2">Morada de faturação</span>
        <div>
          <label for="address" class="font-medium text-900 mb-0">Morada</label>
          <input class="w-full" id="address" type="text" pInputText formControlName="address">
        </div>
        <div>
          <label for="postalCode" class="font-medium text-900">Código postal</label>
          <input class="w-full" id="postalCode" type="text" pInputText formControlName="postalCode">
        </div>
        <div>
          <label for="city" class="font-medium text-900">Cidade</label>
          <input class="w-full" id="city" type="text" pInputText formControlName="city">
        </div>
        <div>
          <label for="country" class="font-medium text-900">País</label>
          <p-dropdown id="country" class="w-full" [options]="countriesList" formControlName="country" optionLabel="name"
                      [filter]="true" filterBy="name"></p-dropdown>
        </div>
        <span class="text-900 text-2xl block font-medium mb-2">Informação de pagamento</span>
        <label for="mobileNumber" class="font-medium text-900">MBWAY</label>
        <p-inputMask id="mobileNumber" formControlName="mobileNumber" placeholder="999999999" mask="999999999"></p-inputMask>
      </form>
    </div>
    <div class="col-12 lg:col-6 px-4 py-4 md:px-6">
      <div class="pb-3 surface-border">
        <span class="text-900 font-medium text-xl">Carrinho</span>
      </div>
      <ng-container *ngIf="(cart$ | async)?.length != 0 || (isLoading$ | async); else empty">
        <div *ngFor="let item of cart$ | async" (click)="goToProduct(item.id)"
             class="flex lg:flex-row lg:align-items-center py-2 mt-3 surface-border cursor-pointer gap-4">
          <img [src]="item.imageUrl ?? './assets/layout/images/avatar.png'" class="product-item w-6rem h-6rem flex-shrink-0 mb-3"
               [alt]="item.name"/>
          <div class="flex-auto">
            <div class="flex justify-content-between mb-3 gap-4">
              <span class="text-900 font-bold">{{ item.name }}</span>
              <div class="flex flex-auto flex-column justify-content-start align-items-end">
                <span class="text-900 font-bold">{{ item.totalPrice | currency: 'EUR': 'symbol': '1.2-2': 'pt-PT' }}</span>
                <button (click)="deleteProduct(item.id, $event)" pButton pRipple icon="pi pi-trash"
                        class="p-button-text p-button-rounded"></button>
              </div>
            </div>
          </div>
        </div>
        <form [formGroup]="form" class="py-2 mt-3 surface-border">
          <p-inputGroup class="mb-3">
            <input
              type="text"
              formControlName="promoCode"
              pInputText
              placeholder="Código promocional"
              class="w-full"
            />
            <button
              type="button"
              pButton
              pRipple
              label="Aplicar"
              [disabled]="!form.value.promoCode"
              (click)="applyPromoCode()"
            ></button>
          </p-inputGroup>
        </form>
      </ng-container>
      <div class="py-2 mt-3">
        <div class="flex justify-content-between align-items-center mb-3">
          <span class="text-900 font-medium">Sub Total</span>
          <span class="text-900">{{ subTotal$ | async | currency: 'EUR': 'symbol': '1.2-2': 'pt-PT' }}</span>
        </div>
        <div class="flex justify-content-between align-items-center mb-3">
          <span class="text-900 font-medium">Código promocional</span>
          <span
            class="text-primary font-bold">{{ dto.discount ? (-dto.discount | currency: 'EUR': 'symbol': '1.2-2': 'pt-PT') : 'N/A' }}</span>
        </div>
        <div class="flex justify-content-between align-items-center mb-3">
          <span class="text-900">IVA</span>
          <span
            class="text-900">{{ (vat$ | async) | currency: 'EUR': 'symbol': '1.2-2': 'pt-PT' }}</span>
        </div>
        <div class="flex justify-content-between align-items-center mb-3">
          <span class="text-900 font-bold">Total</span>
          <span
            class="text-900 font-medium text-xl">{{ total$ | async | currency: 'EUR': 'symbol': '1.2-2': 'pt-PT' }}</span>
        </div>
      </div>
      <div
        *ngIf="(cart$ | async)?.length != 0"
        class="col-12 flex flex-column lg:flex-row justify-content-center align-items-center lg:justify-content-end my-6">
        <p-button (click)="order()" class="p-button-primary w-full lg:w-auto flex-order-1 lg:flex-order-2"
                  label="Pagar" icon="pi pi-fw pi-check"></p-button>
      </div>
    </div>
  </div>
</div>

<ng-template #empty>
  <div class="flex flex-column justify-content-between align-items-center w-full">
    <span>O teu carrinho está vazio</span>
    <img class="my-5 max-h-20rem" src="./assets/illustrations/empty_cart.svg" alt="Empty cart">
  </div>
</ng-template>
