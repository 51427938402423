import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Order } from 'src/app/core/models/order';

@Injectable({
  providedIn: 'root',
})
export class CheckoutDataService {
  private readonly _order$ = new BehaviorSubject<Order | null>(null);
  readonly order$ = this._order$.asObservable();

  constructor() {
  }

  getOrder(): Order | null {
    return this._order$.value;
  }

  setOrder(order: Order): void {
    this._order$.next(order);
  }
}
