import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResourceService } from './resource.service';
import { Observable } from 'rxjs';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root',
})
export class UserService extends ResourceService<User> {
  constructor(http: HttpClient) {
    super(http, 'user');
  }

  getMine(): Observable<User> {
    return this.http.get<User>(`${ this.baseUrl }/user/mine`);
  }

  override update(item: Partial<User>): Observable<User> {
    return this.http
      .put<User>(`${ this.baseUrl }/${ this.endpoint }`, item);
  }
}
