import { Component } from '@angular/core';
import { ListOptions } from 'src/app/core/models/list-options';
import { Order } from 'src/app/core/models/order';
import { OrderService } from 'src/app/core/services/order.service';
import { finalize } from 'rxjs';
import { TableLazyLoadEvent } from "primeng/table";
import { LoaderService } from '../../../core/services/loader.service';

@Component({
  selector: 'order-list',
  templateUrl: './order-list.component.html',
})
export class OrderListComponent {
  orders: Order[] = [];
  totalRecords!: number;
  showItemsDialog = false;
  selectedOrder: Order | undefined;
  listOptions = new ListOptions();

  constructor(
    private orderService: OrderService,
    public loaderService: LoaderService
  ) {
    this.fetchData();
  }

  private fetchData(): void {
    this.loaderService.setLoading(true);
    this.orderService.listPaginated(this.listOptions)
      .pipe(finalize(() => this.loaderService.setLoading(false)))
      .subscribe(res => {
        this.orders = res.data;
        this.totalRecords = res.totalRecords;
      });
  }

  openItemsModal(order: Order): void {
    this.selectedOrder = order;
    this.showItemsDialog = true;
  }

  onItemsModalHide(): void {
    this.showItemsDialog = false;
    this.selectedOrder = undefined;
  }

  loadData(event: TableLazyLoadEvent): void {
    this.listOptions.page = event.first ? event.first / event.rows! + 1 : 1;
    this.listOptions.offset = event.rows!;
    this.fetchData();
  }
}
