import { Component } from '@angular/core';
import { LoaderService } from './core/services/loader.service';
import { Observable } from 'rxjs';
import { LayoutService } from './core/services/layout.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'admin';
  isLoading$: Observable<boolean> = this.loaderService.isLoading$;

  constructor(
    private loaderService: LoaderService,
    private layoutService: LayoutService,
    translate: TranslateService,
  ) {
    translate.setDefaultLang('pt');
    translate.use('pt');

  }
}
