import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { OrderService } from 'src/app/core/services/order.service';
import { CheckoutDataService } from 'src/app/features/checkout/checkout.data-service';
import { ActivatedRoute, Router } from '@angular/router';
import { Order } from 'src/app/core/models/order';
import { PaymentStatus } from 'src/app/core/models/enum/payment-status.enum';
import { MessageService } from 'primeng/api';

@Component({
  templateUrl: './mb-way.component.html',
})
export class MbWayComponent implements OnInit, OnDestroy {
  private startDate: Date | undefined;
  private order: Order | null = null;

  countDown?: Subscription;
  counter = 0;
  knobValue = 0;

  constructor(
    private orderService: OrderService,
    private checkoutDataService: CheckoutDataService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router) {
  }

  ngOnInit(): void {
    this.order = this.checkoutDataService.getOrder();
    if (!this.order) {
      const orderId = this.route.snapshot.queryParams['id'];
      if (!orderId) {
        return this.showErrorAndNavigate();
      }
      this.orderService.read(orderId)
        .subscribe((order) => {
          if (!order) {
            return this.showErrorAndNavigate();
          }
          this.checkoutDataService.setOrder(order);
          this.order = order;
          this.startDate = new Date(order.payment.createdAt);
          this.initCountdown();
        })
    } else {
      this.startDate = new Date(this.order.payment.createdAt);
      this.initCountdown();
    }
  }

  private showErrorAndNavigate(): void {
    this.messageService.add({ severity: 'warn', detail: 'Ocorreu um erro' });
    setTimeout(() => this.router.navigate(['/']), 500);
  }

  private initCountdown(): void {
    if (!this.startDate) {
      return;
    }
    const now = new Date(Date.now());
    const secondsBetween = (now.valueOf() - this.startDate.valueOf()) / 1000;
    this.counter = 300 - secondsBetween;
    this.countDown = timer(0, 1000).subscribe(() => {
      if (this.counter > 0) {
        --this.counter;
        this.knobValue = this.counter * 100 / 300;
      }
    });
  }

  validatePayment(): void {
    if (!this.order) return;
    this.orderService.getPaymentStatus(this.order.id).subscribe((result) => {
      switch (result.status) {
        case PaymentStatus.CANCELLED:
        case PaymentStatus.EXPIRED: {
          this.messageService.add({ severity: 'error', detail: 'O pagamento foi cancelado ou expirou.' });
          this.router.navigate(['/product']);
          break;
        }
        case PaymentStatus.PENDING: {
          this.messageService.add({ severity: 'warn', detail: 'O pagamento ainda está pendente.' });
          break;
        }
        case PaymentStatus.COMPLETED: {
          this.messageService.add({ severity: 'success', detail: 'O pagamento foi efetuado com sucesso.' });
          this.router.navigate(['/product/mine']);
          break;
        }
      }
    })
  }

  ngOnDestroy(): void {
    this.countDown?.unsubscribe();
  }
}
