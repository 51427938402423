import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShoppingSession } from '../models/shopping-session';
import { ResourceService } from './resource.service';
import { map, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ShoppingSessionService extends ResourceService<ShoppingSession> {

  constructor(http: HttpClient) {
    super(http, 'cart');
  }

  getMine(): Observable<ShoppingSession> {
    return this.http.get<ShoppingSession>(`${ this.baseUrl }/cart`)
      .pipe(filter(session => !!session),map(session => ({
        ...session,
        items: session.items.map((item) => {
          const product = item.product
          if (!product) {
            return item;
          }

          let totalPrice = product.basePrice;
          if (product.discount) {
            if (product.discountType === 'PERCENTAGE') {
              totalPrice -= (product.discount * product.basePrice) / 100;
            } else {
              totalPrice -= product.discount;
            }
          }

          product.totalPrice = totalPrice
          return {
            ...item,
            product,
          }
        })
      })));
  }

  deleteProduct(productId: string): Observable<void> {
    return this.http.delete<void>(`${ this.baseUrl }/cart/${ productId }`);
  }

  override update(item: Partial<ShoppingSession>): Observable<ShoppingSession> {
    return this.http
      .put<ShoppingSession>(`${ this.baseUrl }/${ this.endpoint }`, item);
  }
}
