import { Component, ElementRef, ViewChild } from '@angular/core';
import { LayoutService } from 'src/app/features/layout/service/app.layout.service';
import { AuthService } from '../../core/services/auth.service';
import { Router } from '@angular/router';
import { ConfirmationService, MenuItem } from 'primeng/api';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
})
export class AppTopbarComponent {
  @ViewChild('menubutton') menuButton!: ElementRef;

  isLoggedIn$ = this.authService.isLoggedIn$;
  items: MenuItem[] | undefined;

  constructor(
    public layoutService: LayoutService,
    private authService: AuthService,
    private confirmationService: ConfirmationService,
    private router: Router) {
    this.isLoggedIn$.subscribe(isLoggedIn => {
      this.setTopBarItems(isLoggedIn);
    })
  }

  goToLogin(): void {
    this.router.navigate(['/auth/login']);
  }

  goToRegister(): void {
    this.router.navigate(['/auth/register']);
  }

  onLogoutButtonClick(event: Event) {
    this.confirmationService.confirm({
      target: event.target || new EventTarget,
      acceptLabel: 'Sim',
      rejectLabel: 'Não',
      message: 'Tem a certeza que quer sair?',
      accept: () => {
        this.authService.logout().subscribe(() => {
          this.router.navigate(['/product']);
        });
      },
    });
  }

  private setTopBarItems(isLoggedIn: boolean): void {
    const common = [
      {
        label: 'Loja',
        icon: 'pi pi-fw pi-globe',
        routerLink: '/product'
      },
    ]
    this.items = !isLoggedIn
      ?
      common
      :
      [
        ...common,
        {
          label: 'Meus Produtos',
          icon: 'pi pi-fw pi-download',
          routerLink: '/product/mine',
        },
        {
          label: 'Minhas Encomendas',
          icon: 'pi pi-fw pi-book',
          routerLink: ['/order'],
        },
        {
          label: 'Definições',
          icon: 'pi pi-fw pi-cog',
          items: [
            {
              label: 'Perfil',
              icon: 'pi pi-fw pi-user',
              routerLink: 'settings',
              fragment: 'profile',
            },
            {
              label: 'Reviews',
              icon: 'pi pi-fw pi-star',
              routerLink: 'settings',
              fragment: 'reviews',
            }
          ]
        },
      ];
  }
}
