import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { ImageModule } from 'primeng/image';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { PasswordModule } from 'primeng/password';
import { ProgressBarModule } from 'primeng/progressbar';
import { RippleModule } from 'primeng/ripple';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { OrderListComponent } from 'src/app/features/order/list/order-list.component';
import { OrderRoutingModule } from 'src/app/features/order/order-routing.module';
import { OrderDetailComponent } from 'src/app/features/order/detail/order-detail.component';
import { TranslateModule } from '@ngx-translate/core';
import { PaginatorModule } from 'primeng/paginator';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  declarations: [
    OrderListComponent,
    OrderDetailComponent,
  ],
  imports: [
    CommonModule,
    OrderRoutingModule,
    ButtonModule,
    RippleModule,
    InputTextModule,
    CheckboxModule,
    FormsModule,
    PasswordModule,
    TableModule,
    ProgressBarModule,
    DropdownModule,
    FileUploadModule,
    InputTextareaModule,
    InputGroupModule,
    InputGroupAddonModule,
    ReactiveFormsModule,
    SelectButtonModule,
    InputSwitchModule,
    InputNumberModule,
    ImageModule,
    ToastModule,
    TabViewModule,
    DialogModule,
    ConfirmPopupModule,
    CurrencyPipe,
    ButtonModule,
    RippleModule,
    TranslateModule,
    TableModule,
    TableModule,
    InputTextModule,
    PaginatorModule,
    ReactiveFormsModule,
    SharedModule,
    SharedModule,
    SharedModule,
  ],
  providers: [],
})
export class OrderModule {
}
