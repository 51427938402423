import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResourceService } from 'src/app/core/services/resource.service';
import { CouponCode } from 'src/app/core/models/coupon-code';

@Injectable({
  providedIn: 'root',
})
export class CouponCodeService extends ResourceService<CouponCode> {
  constructor(http: HttpClient) {
    super(http, 'coupon-code');
  }

  getCouponCode(name: string): Observable<CouponCode> {
    return this.http.get<CouponCode>(`${ this.baseUrl }/coupon-code/name/${ name }`);
  }
}
