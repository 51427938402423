<p-dialog [modal]="true" [header]="'Encomenda ' + order?.number" [(visible)]="visible"
          [style]="{width: '80vw'}" (onHide)="onDialogHide()">
  <form [formGroup]="form" class="grid formgrid p-fluid">
    <span class="text-500 text-l font-bold block mb-4 col-12">Dados de faturação</span>
    <div class="field mb-4 col-12 md:col-4">
      <label for="nif" class="font-medium text-900">NIF</label>
      <input id="nif" type="text" pInputText formControlName="nif" [readOnly]="true">
    </div>
    <div class="field mb-4 col-12 md:col-6">
      <label for="phoneNumber" class="font-medium text-900">Telemóvel</label>
      <input id="phoneNumber" type="text" pInputText formControlName="phoneNumber" [readOnly]="true">
    </div>
    <div class="field mb-4 col-12">
      <label for="address" class="font-medium text-900">Morada</label>
      <input id="address" type="text" pInputText formControlName="address" [readOnly]="true">
    </div>
    <div class="field mb-4 col-12 md:col-4">
      <label for="postalCode" class="font-medium text-900">Código postal</label>
      <input id="postalCode" type="text" pInputText formControlName="postalCode" [readOnly]="true">
    </div>
    <div class="field mb-4 col-12 md:col-4">
      <label for="city" class="font-medium text-900">Cidade</label>
      <input id="city" type="text" pInputText formControlName="city" [readOnly]="true">
    </div>
    <div class="field mb-4 col-12 md:col-4">
      <label for="country" class="font-medium text-900">País</label>
      <input id="country" type="text" pInputText formControlName="country" [readOnly]="true">
    </div>
  </form>

  <span class="text-500 text-l font-bold block mb-4 col-12">Produtos</span>
  <p-table *ngIf="order" [value]="order.items" responsiveLayout="scroll">
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="name" class="white-space-nowrap" style="width:60%">Nome</th>
        <th pSortableColumn="price" class="white-space-nowrap" style="width:30%">Preço</th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
      <tr>
        <td>{{ item.productName }}</td>
        <td>{{ item.productPrice | currency: 'EUR': 'symbol': '1.2-2': 'pt-PT' }}</td>
        <td class="flex">
          <button pButton pRipple icon="pi pi-eye" class="p-button-rounded p-button-success mr-2 w-2rem h-2rem"
                  (click)="goToProduct(item.productId)"></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</p-dialog>
