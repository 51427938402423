import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from 'src/app/core/services/cart.service';
import { map, Observable } from 'rxjs';
import { ShoppingSessionService } from 'src/app/core/services/shopping-session.service';

@Component({
  selector: 'app-topbar-cart',
  templateUrl: './app.topbar-cart.component.html'
})
export class AppTopbarCartComponent implements OnInit {
  cartItems$: Observable<number> = this.cartService.cart$.pipe(map((products) => products.length ?? 0));

  constructor(
    private cartService: CartService,
    private shoppingSessionService: ShoppingSessionService,
    private router: Router) {
  }

  ngOnInit(): void {
    this.shoppingSessionService.getMine().subscribe((session) => {
      if (session) {
        this.cartService.setProducts(session.items.map(item => ({ ...item.product! })));
      }
    })
  }

  onButtonClick() {
    this.router.navigate(['/checkout']);
  }

}
