import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from './features/layout/app.layout.component';
import { authGuard } from 'src/app/core/guards/auth.guard';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';

const routes: Routes = [
  { path: 'auth', loadChildren: () => import('./features/auth/auth.module').then(m => m.AuthModule) },
  {
    path: '',
    component: AppLayoutComponent,
    children: [
      { path: '', redirectTo: '/product', pathMatch: 'full' },
      { path: 'product', loadChildren: () => import('./features/product/product.module').then(m => m.ProductModule) },
      { path: 'order', canActivate: [authGuard], loadChildren: () => import('./features/order/order.module').then(m => m.OrderModule) },
      {
        path: 'checkout',
        canActivate: [authGuard],
        loadChildren: () => import('./features/checkout/checkout.module').then(m => m.CheckoutModule)
      },
      {
        path: 'settings',
        canActivate: [authGuard],
        loadChildren: () => import('./features/settings/settings.module').then(m => m.SettingsModule)
      },
    ]
  },
  { path: '**', pathMatch: 'full', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
