import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Resource } from '../models/resource';
import { ListOptions } from '../models/list-options';
import { environment } from 'src/environments/environment';

export class ResourceService<T extends Resource> {
  protected baseUrl = environment.apiUrl;

  constructor(
    protected http: HttpClient,
    protected endpoint: string) {
  }

  create(item: Partial<T>): Observable<T> {
    return this.http
      .post<T>(`${ this.baseUrl }/${ this.endpoint }`, item);
  }

  createFormData(item: Partial<T>, files: File[]): Observable<T> {
    const formData = new FormData();
    for (const file of files) {
      formData.append(`file-${ file.name }`, file);
    }
    for (const key of Object.keys(item)) {
      if ((item as any)[key] != null) {
        formData.append(key, (item as any)[key]);
      }
    }
    return this.http
      .post<T>(`${ this.baseUrl }/${ this.endpoint }`, formData);
  }

  update(item: Partial<T>): Observable<T> {
    return this.http
      .put<T>(`${ this.baseUrl }/${ this.endpoint }/${ item.id }`, item);
  }

  updateFormData(item: Partial<T>, files: File[]): Observable<T> {
    const formData = new FormData();
    for (const file of files) {
      formData.append(`file-${ file.name }`, file);
    }
    for (const key of Object.keys(item)) {
      if ((item as any)[key] != null) {
        if (Array.isArray((item as any)[key])) {
          formData.append(key, JSON.stringify((item as any)[key]));
        } else {
          formData.append(key, (item as any)[key]);
        }
      }
    }
    return this.http
      .put<T>(`${ this.baseUrl }/${ this.endpoint }/${ item.id }`, formData);
  }


  read(id: string): Observable<T> {
    return this.http
      .get<T>(`${ this.baseUrl }/${ this.endpoint }/${ id }`);
  }

  list(options?: ListOptions): Observable<T[]> {
    let url = `${ this.baseUrl }/${ this.endpoint }`;
    if (options) {
      url += options?.toQueryString();
    }
    return this.http.get<T[]>(url);
  }

  listPaginated(options?: ListOptions): Observable<{ data: T[]; totalRecords: number }> {
    let url = `${ this.baseUrl }/${ this.endpoint }`;
    if (options) {
      url += options?.toQueryString();
    }
    return this.http.get<{ data: T[]; totalRecords: number }>(url);
  }

  delete(id: string): Observable<void> {
    return this.http
      .delete<void>(`${ this.baseUrl }/${ this.endpoint }/${ id }`);
  }
}
