import { HttpClient, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, EMPTY, finalize, Observable, of, shareReplay, tap } from 'rxjs';
import { LoginResponse } from '../models/dto/login.response';
import { ResourceService } from 'src/app/core/services/resource.service';
import { Resource } from 'src/app/core/models/resource';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends ResourceService<Resource> {
  private readonly _isLoggedIn$ = new BehaviorSubject<boolean>(false);
  readonly isLoggedIn$ = this._isLoggedIn$.asObservable();

  constructor(http: HttpClient, private router: Router) {
    super(http, 'auth');
    if (localStorage.getItem('accessToken')) {
      this._isLoggedIn$.next(true);
    }
  }

  signup(email: string, password: string, firstName: string, lastName: string): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(`${ this.baseUrl }/auth/signup`, { email, password, firstName, lastName })
      .pipe(tap((res) => this.setSession(res)), shareReplay());
  }

  login(email: string, password: string): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(`${ this.baseUrl }/auth/login`, { email, password })
      .pipe(tap((res) => this.setSession(res)), shareReplay());
  }

  logout(): Observable<boolean> {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
      this.removeSession();
      return of(true);
    }

    return this.http.post<boolean>(`${ this.baseUrl }/auth/logout`, { refreshToken })
      .pipe(finalize(() => {
        this.removeSession();
      }));
  }

  refreshToken(token: string): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(`${ this.baseUrl }/auth/refresh`, { refreshToken: token })
      .pipe(catchError((error) => {
        if (error?.status === HttpStatusCode.Unauthorized) {
          this.router.navigate(['/auth/login']);
          throw EMPTY;
        }

        throw error;
      }), tap((res) => this.setSession(res)), shareReplay());
  }

  isLoggedIn(): boolean {
    return this._isLoggedIn$.value;
  }

  private setSession(loginResponse: LoginResponse): void {
    localStorage.setItem('accessToken', loginResponse.accessToken);
    localStorage.setItem('refreshToken', loginResponse.refreshToken);
    this._isLoggedIn$.next(true);
  }

  private removeSession(): void {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    this._isLoggedIn$.next(false);
  }
}
