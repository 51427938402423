import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { MessageService } from 'primeng/api';

@Component({
  templateUrl: './register.component.html',
})
export class RegisterComponent {
  form: FormGroup = new FormGroup({
    email: new FormControl(undefined, [Validators.required, Validators.email]),
    password: new FormControl(undefined, [Validators.required]),
    firstName: new FormControl(undefined, [Validators.required]),
    lastName: new FormControl(undefined, [Validators.required]),
    acceptTerms: new FormControl(false, [Validators.requiredTrue]),
  });

  constructor(
    private authService: AuthService,
    private messageService: MessageService,
    private router: Router) {
  }

  register() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.authService.signup(
        this.form.value.email,
        this.form.value.password,
        this.form.value.firstName,
        this.form.value.lastName,
      ).subscribe(_ => {
        this.messageService.add({ severity: 'success', detail: 'A redirecionar...', summary: 'Registo efetuado com sucesso' });
        setTimeout(() => {
          this.router.navigate(['/']);
        }, 1000);
      });
    }
  }
}
