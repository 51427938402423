import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Order } from 'src/app/core/models/order';
import { Router } from '@angular/router';
import { PaymentStatus } from 'src/app/core/models/enum/payment-status.enum';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'order-detail',
  templateUrl: './order-detail.component.html'
})
export class OrderDetailComponent {
  @Input() visible: boolean = false;
  @Input() set order(value: Order | undefined) {
    this._order = value;
    this.setForm();
  }

  @Output() hideEvent: EventEmitter<void> = new EventEmitter();

  private _order: Order | undefined;
  get order(): Order | undefined {
    return this._order;
  }

  form: FormGroup = new FormGroup({
    id: new FormControl(undefined),
    phoneNumber: new FormControl(undefined),
    nif: new FormControl(undefined),
    address: new FormControl(undefined),
    postalCode: new FormControl(undefined),
    city: new FormControl(undefined),
    country: new FormControl(undefined)
  });

  constructor(private router: Router) {
  }

  onDialogHide(): void {
    this.hideEvent.next();
  }

  goToProduct(productId: string): void {
    if (this.order?.payment.status === PaymentStatus.COMPLETED) {
      this.router.navigate(['product/mine', productId]);
    } else {
      this.router.navigate(['product', productId]);
    }
  }

  private setForm(): void {
    if (!this._order) {
      return;
    }
    this.form.patchValue({
      id: this._order.id,
      phoneNumber: this._order.payment.phoneNumber,
      nif: this._order.nif,
      address: this._order.address,
      postalCode: this._order.postalCode,
      city: this._order.city,
      country: this._order.country,
    });
  }
}
