import { Injectable } from '@angular/core';
import { Product } from '../models/product';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  private readonly _cart$ = new BehaviorSubject<Product[]>([]);
  readonly cart$ = this._cart$.asObservable();

  get isPromoCodeApplied(): boolean {
    return this._isPromoCodeApplied;
  }

  set isPromoCodeApplied(value: boolean) {
    this._isPromoCodeApplied = value;
  }
  private _isPromoCodeApplied: boolean = false;

  constructor() {
  }

  getProducts(): Product[] {
    return this._cart$.value;
  }

  setProducts(products: Product[]): void {
    this._cart$.next(products);
  }

  addProduct(product: Product): void {
    const currentProducts = this._cart$.value;
    const updatedProducts = [...currentProducts, product];
    this._cart$.next(updatedProducts);
  }

  removeProduct(productId: string): void {
    const currentProducts = this._cart$.value;
    const updatedProducts = currentProducts.filter(product => product.id !== productId);
    this._cart$.next(updatedProducts);
  }

  clearCart(): void {
    this._cart$.next([]);
  }
}
