<div class="flex flex-column justify-content-center align-items-center h-full">
  <p-messages severity="info">
    <ng-template pTemplate>
      <span>Após efetuada a compra, os produtos ficam disponíveis na aba <b>Os Meus Produtos</b></span>
    </ng-template>
  </p-messages>
  <div class="mt-8 flex flex-column align-items-center">
    <h2>{{ counter | formatTime }}</h2>
    <img class="max-h-20rem mt-4" src="./assets/illustrations/mobile_pay.svg">
  </div>
  <button (click)="validatePayment()" pButton pRipple label="Já paguei"
          class="flex-unset p-button-outlined mt-5"></button>
</div>
