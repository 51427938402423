import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

  model: any[] = [];

  ngOnInit() {
    this.model = [
      {
        label: 'Loja',
        items: [
          {
            label: 'Produtos',
            icon: 'pi pi-fw pi-book',
            routerLink: ['/product'],
          },
          {
            label: 'Meus Produtos',
            icon: 'pi pi-fw pi-book',
            routerLink: ['/product/mine'],
          },
          {
            label: 'Minhas Encomendas',
            icon: 'pi pi-fw pi-book',
            routerLink: ['/order'],
          },
        ]
      },
      {
        label: 'Definições',
        items: [
          {
            label: 'Perfil',
            icon: 'pi pi-fw pi-user',
            routerLink: ['/profile'],
          },
        ]
      }
    ];
  }
}
