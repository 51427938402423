import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, EMPTY, Observable, switchMap, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.get('AccessKey') || req.url.includes('/auth/refresh') || req.url.includes('/auth/logout')) {
      return next.handle(req);
    }

    const token = localStorage.getItem('accessToken');

    if (token) {
      const cloned = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + token)
      });

      return next.handle(cloned)
        .pipe(catchError((error: HttpErrorResponse) => {
          if (error?.status === HttpStatusCode.Unauthorized && !error.url?.includes('/auth/login')) {
            return this.refreshToken(req, next);
          }

          return throwError(() => error);
        }));
    } else {
      return next.handle(req);
    }
  }

  private refreshToken(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('refreshToken');
    if (!token) {
      return throwError(() => 'No refresh token');
    }

    return this.authService.refreshToken(token)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error?.status === HttpStatusCode.Unauthorized) {
            this.router.navigate(['/auth/login']);
            throw EMPTY;
          }

          return throwError(() => error);
        }),
        switchMap((res) => {
          const cloned = req.clone({
            headers: req.headers.set('Authorization', 'Bearer ' + res.accessToken)
          });

          return next.handle(cloned);
        })
      );
  }
}
