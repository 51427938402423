<div class="card mt-3 h-full">
  <div class="text-900 font-medium text-4xl mb-4">Minhas Encomendas</div>
  <ng-container *ngIf="orders && orders.length > 0">
    <p-table [value]="orders" responsiveLayout="scroll" [showLoader]="false"
             [paginator]="true" [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]"
             currentPageReportTemplate="Mostrar {first} a {last} de {totalRecords} entradas"
             [lazy]="true" (onLazyLoad)="loadData($event)" [totalRecords]="totalRecords">
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="number" class="white-space-nowrap" style="width:25%">Nº
            <p-sortIcon field="number"></p-sortIcon>
          </th>
          <th pSortableColumn="status" class="white-space-nowrap" style="width:25%">Estado
            <p-sortIcon field="status"></p-sortIcon>
          </th>
          <th pSortableColumn="total" class="white-space-nowrap" style="width:25%">Total
            <p-sortIcon field="total"></p-sortIcon>
          </th>
          <th pSortableColumn="createdAt" class="white-space-nowrap" style="width:25%">Data
            <p-sortIcon field="createdAt"></p-sortIcon>
          </th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-order>
        <tr>
          <td>{{ order.number }}</td>
          <td>{{ order.payment?.status | translate }}</td>
          <td>{{ order.total | currency: 'EUR': 'symbol': '1.2-2': 'pt-PT' }}</td>
          <td>{{ order.createdAt | date: 'dd/MM/yyyy' }}</td>
          <td class="flex">
            <button pButton pRipple icon="pi pi-eye" class="p-button-rounded p-button-success mr-2 w-2rem h-2rem"
                    (click)="openItemsModal(order)"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </ng-container>
  <ng-container *ngIf="!(loaderService.isLoading$ | async) && orders?.length === 0">
    <div class="flex flex-column justify-content-between align-items-center w-full">
      <span>Ainda não tens nenhuma encomenda</span>
      <img class="mt-5 max-w-30rem w-full" src="./assets/illustrations/no_data.svg" alt="No data">
    </div>
  </ng-container>
</div>

<order-detail
  [visible]="showItemsDialog"
  [order]="selectedOrder"
  (hideEvent)="onItemsModalHide()">
</order-detail>
