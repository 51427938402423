import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';

import { AsyncPipe, CommonModule, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import locale from '@angular/common/locales/pt-PT';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfirmationService, SharedModule as PrimeSharedModule } from 'primeng/api';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { MenubarModule } from 'primeng/menubar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { AuthModule } from './features/auth/auth.module';
import { AppLayoutModule } from './features/layout/app.layout.module';
import { CheckoutModule } from './features/checkout/checkout.module';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { OrderModule } from 'src/app/features/order/order.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from './shared/shared.module';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { ToastModule } from 'primeng/toast';

registerLocaleData(locale);

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AuthModule,
    ProgressSpinnerModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    MenubarModule,
    HttpClientModule,
    AvatarModule,
    AvatarGroupModule,
    AppLayoutModule,
    CheckoutModule,
    OrderModule,
    AsyncPipe,
    PrimeSharedModule,
    SharedModule,
    ToastModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'pt-PT' },
    { provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    ConfirmationService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
