export class ListOptions {
  page?: number;
  offset?: number;
  filters?: { [key: string]: string }[];

  constructor(page: number = 1, offset: number = 10, filters: { [key: string]: string }[] = []) {
    this.page = page;
    this.offset = offset;
    this.filters = filters;
  }

  toQueryString(): string {
    let query = `?page=${ this.page }&offset=${ this.offset }`;
    for (const filter of this.filters ?? []) {
      const key = Object.keys(filter)[0];
      query += `&${ key }=${ filter[key] }`;
    }

    return query;
  }
}
