import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, combineLatest, finalize, map, Observable, tap } from 'rxjs';
import { Product } from 'src/app/core/models/product';
import { CartService } from 'src/app/core/services/cart.service';
import { OrderService } from 'src/app/core/services/order.service';
import { ShoppingSessionService } from 'src/app/core/services/shopping-session.service';
import { UserService } from 'src/app/core/services/user.service';
import { countries } from 'src/assets/data/countries';
import { Router } from '@angular/router';
import { CheckoutDataService } from 'src/app/features/checkout/checkout.data-service';
import { CouponCodeService } from 'src/app/core/services/coupon-code.service';
import { DiscountType } from 'src/app/core/models/enum/discount-type.enum';
import { CouponCode } from 'src/app/core/models/coupon-code';
import { LoaderService } from '../../../core/services/loader.service';

@Component({
  templateUrl: './checkout-form.component.html',
  styles: `
    p-dropdown::ng-deep > div {
      width: 100%;
    }

    .product-item {
      object-fit: cover;
    }
  `
})
export class CheckoutFormComponent implements OnInit {
  isLoading$ = this.loaderService.isLoading$;
  form = new FormGroup({
    // paymentMethod: new FormControl(undefined, Validators.required),
    mobileNumber: new FormControl<string | undefined>(undefined,  [Validators.minLength(9), Validators.maxLength(9), Validators.pattern(new RegExp('(9[1236]\\d) ?(\\d{3}) ?(\\d{3})'))]),
    promoCode: new FormControl(),
    address: new FormControl<string | undefined>(undefined),
    postalCode: new FormControl<string | undefined>(undefined),
    city: new FormControl<string | undefined>(undefined),
    country: new FormControl({ name: 'Portugal', code: 'Portugal' }, Validators.required),
  });
  paymentMethods = [
    { name: 'MB', code: 'MB' },
    { name: 'MBWAY', code: 'MBWAY' },
  ];
  promoCodeDiscount: BehaviorSubject<CouponCode | null> = new BehaviorSubject<CouponCode | null>(null);
  promoCodeDiscount$ = this.promoCodeDiscount.asObservable();
  countriesList = countries.map(country => ({ name: country, code: country }));
  private couponCode: CouponCode | null = null;

  cart$: Observable<Product[]> = this.cartService.cart$
    .pipe(
      tap((products) => this.dto.productIds = products.map(product => product.id)),
      map((products) => products.map(product => ({
        ...product,
        imageUrl: product.attachments && product.attachments.length > 0 ? product.attachments[0].path : undefined
      })))
    );
  subTotal$ = this.cart$.pipe(map(products => products.reduce((a, b) => a + Number(b.totalPrice), 0)));
  vat$ = combineLatest([
    this.subTotal$,
    this.promoCodeDiscount$
  ]).pipe((map(([subTotal, couponCode]) => {
    this.couponCode = couponCode;
    if (couponCode) {
      if (couponCode.discountType === DiscountType.PERCENTAGE) {
        this.dto.discount = Math.round(((subTotal * couponCode.discount) / 100) * 1e2) / 1e2;
      } else {
        this.dto.discount = subTotal - couponCode.discount;
      }
    }
    return (subTotal - (this.dto.discount ?? 0)) * 0.23;
  })));
  total$ = combineLatest([
    this.subTotal$,
    this.promoCodeDiscount$,
    this.vat$,
  ]).pipe(
    (map(([subTotal, couponCode, vat]) => {
      this.dto.total = subTotal;
      this.couponCode = couponCode;
      if (couponCode) {
        if (couponCode.discountType === DiscountType.PERCENTAGE) {
          this.dto.discount = Math.round(((subTotal * couponCode.discount) / 100) * 1e2) / 1e2;
        } else {
          this.dto.discount = subTotal - couponCode.discount;
        }
        this.dto.total = subTotal - this.dto.discount;
      }
      return this.dto.total + vat;
    })),
  );

  dto: { total: number, discount: number | null, productIds: string[] } = { total: 0, discount: null, productIds: [] };

  constructor(
    private cartService: CartService,
    private shoppingSessionService: ShoppingSessionService,
    private orderService: OrderService,
    private userService: UserService,
    private couponCodeService: CouponCodeService,
    private messageService: MessageService,
    private checkoutDataService: CheckoutDataService,
    private router: Router,
    private loaderService: LoaderService,
  ) {
    if (this.cartService.getProducts()?.length === 0) {
      this.fetchShoppingSession();
    } else {
      this.loaderService.setLoading(false);
    }
  }

  ngOnInit(): void {
    this.userService.getMine().subscribe(user => {
      this.form.patchValue({
        address: user.address,
        postalCode: user.postalCode,
        city: user.city,
        country: user.country ? { name: user.country, code: user.country } : undefined,
        mobileNumber: user.phoneNumber,
      })
    });
  }

  private fetchShoppingSession(): void {
    setTimeout(() => {
    this.loaderService.setLoading(true);

    })
    this.shoppingSessionService.getMine()
      .pipe(finalize(() => this.loaderService.setLoading(false)))
      .subscribe((session) => {
        if (session) {
          this.cartService.setProducts(session.items.map(item => ({
            ...item.product!,
            imageUrl: item.product!.attachments && item.product!.attachments.length > 0 ? item.product!.attachments[0].path : undefined
          })));
        }
      });
  }

  goToProduct(productId: string): void {
    this.router.navigate(['product', productId]);
  }

  deleteProduct(productId: string, event: MouseEvent): void {
    event.stopPropagation();
    this.shoppingSessionService.deleteProduct(productId)
      .subscribe(() => {
        this.cartService.setProducts(this.cartService.getProducts().filter(item => item.id !== productId));
        this.messageService.add({ severity: 'success', detail: `Produto removido do carrinho` });
      });
  }

  applyPromoCode(): void {
    if (this.form.value.promoCode && !this.cartService.isPromoCodeApplied) {
      this.loaderService.setLoading(true);
      this.couponCodeService.getCouponCode(this.form.value.promoCode)
        .pipe(finalize(() => this.loaderService.setLoading(false)))
        .subscribe(couponCode => {
        if (!couponCode) {
          this.messageService.add({ severity: 'error', detail: 'Código promocional inválido' });
        } else {
          this.cartService.isPromoCodeApplied = true;
          this.messageService.add({ severity: 'success', detail: 'Código promocional aplicado' });
          this.promoCodeDiscount.next(couponCode);
        }
      })
    }
  }

  order(): void {
    if (this.form.valid) {
      this.loaderService.setLoading(true);
      // const paymentMethod = (this.form.value.paymentMethod as any).code;
      this.orderService.createOrder({
        total: this.dto.total,
        items: this.dto.productIds.map(productId => ({ productId })),
        couponCodeId: this.couponCode?.id,
        paymentMethod: 'MBWAY',
        mobileNumber: this.form.value.mobileNumber!,
      }).pipe(finalize(() => this.loaderService.setLoading(false)))
        .subscribe((order) => {
        this.checkoutDataService.setOrder(order);
        this.cartService.clearCart();
        this.router.navigate(['/checkout/mbway'], { queryParams: { id: order.id } });
      });
    }
  }
}
