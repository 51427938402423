import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LoaderComponent } from "./components/loader/loader.component";
import { ProgressBarModule } from "primeng/progressbar";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { SharedModule as PrimeSharedModule } from "primeng/api";
import { TableModule } from "primeng/table";
import { ButtonModule } from "primeng/button";
import { RippleModule } from "primeng/ripple";
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    LoaderComponent,
    PageNotFoundComponent,
  ],
  imports: [
    CommonModule,
    ProgressBarModule,
    ProgressSpinnerModule,
    PrimeSharedModule,
    TableModule,
    ButtonModule,
    RippleModule,
    RouterModule,
  ],
  providers: [],
  exports: [
    LoaderComponent,
  ]
})
export class SharedModule {
}
