import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { catchError, EMPTY } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { CartService } from 'src/app/core/services/cart.service';
import { ShoppingSessionService } from 'src/app/core/services/shopping-session.service';

@Component({
  templateUrl: './login.component.html',
})
export class LoginComponent {
  form: FormGroup = new FormGroup({
    email: new FormControl(undefined, [Validators.required, Validators.email]),
    password: new FormControl(undefined, [Validators.required]),
    rememberMe: new FormControl(false),
  });

  constructor(
    private authService: AuthService,
    private shoppingSessionService: ShoppingSessionService,
    private cartService: CartService,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute) {
  }

  login() {
    if (this.form.valid) {
      this.authService.login(this.form.value.email, this.form.value.password)
        .pipe(catchError((error) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === HttpStatusCode.Unauthorized) {
              this.messageService.add({ severity: 'error', summary: 'Credenciais inválidas', detail: 'Por favor tente outra combinação' })
            }
          }

          throw EMPTY;
        }))
        .subscribe(() => {
          let navigateTo: string[] = [];
          const extras: NavigationExtras = {};
          const returnUrl = this.route.snapshot.queryParams['returnUrl'];
          if (returnUrl) {
            if (returnUrl.includes('checkout')) {
              const items = this.cartService.getProducts();
              this.shoppingSessionService.create({
                total: items.reduce((a, b) => a + Number(b.basePrice), 0),
                items: items.map(item => ({ productId: item.id })),
              }).subscribe(() => this.router.navigate([returnUrl]));
            }
            const parts = returnUrl.split('#');
            if (parts.length == 2) {
              navigateTo.push(parts[0]);
              extras.fragment = parts[1];
            } else {
              navigateTo.push(parts[0]);
            }
          } else {
            navigateTo.push('/');
          }

          this.router.navigate(navigateTo, extras);
        });
    }
  }
}
