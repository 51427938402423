import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  private profileOpen = new BehaviorSubject<boolean>(false);
  profileOpen$ = this.profileOpen.asObservable();

  constructor() {
  }

  openProfileSidebar(): void {
    this.profileOpen.next(true);
  }

  closeProfileSidebar(): void {
    this.profileOpen.next(false);
  }
}
