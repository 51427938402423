<p-menubar [model]="items">
  <ng-template pTemplate="end">
    <ul class="topbar-menu">
      <li class="ml-3">
        <app-topbar-cart *ngIf="isLoggedIn$ | async"></app-topbar-cart>
        <button *ngIf="(isLoggedIn$ | async) === false" (click)="goToRegister()" pButton pRipple label="Registo"
                style="height:35px" class="p-button-outlined mr-2"></button>
        <button *ngIf="(isLoggedIn$ | async) === false" (click)="goToLogin()" pButton pRipple label="Login"
                style="height:35px"></button>
        <i *ngIf="isLoggedIn$ | async" class="pi pi-sign-out ml-5 cursor-pointer" style="font-size: 1.2rem"
           (click)="onLogoutButtonClick($event)"></i>
        <p-confirmPopup></p-confirmPopup>
      </li>
    </ul>
  </ng-template>
</p-menubar>
