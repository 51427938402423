import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { catchError, EMPTY, Observable } from 'rxjs';

class HttpError {
  static BadRequest = 400;
  static Unauthorized = 401;
  static Forbidden = 403;
  static NotFound = 404;
  static Conflict = 409;
  static InternalServerError = 500;
}

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private logFormat = 'background: maroon; color: white';

  constructor(
    private messageService: MessageService,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError((error) => {
      if (error instanceof HttpErrorResponse) {
        if (req.url.includes('/cart') && req.method === 'GET') {
          throw EMPTY;
        }
        switch (error.status) {
          case HttpError.BadRequest:
            console.error('%c Bad request', this.logFormat, error.message);
            break;
          case HttpError.Forbidden:
            console.warn('%c Forbidden', this.logFormat, error.message);
            this.messageService.add({ severity: 'warn', detail: 'Permission denied' });
            break;
          case HttpError.NotFound:
            console.info('%c Not found', this.logFormat, error.message);
            break;
          case HttpError.Conflict:
            console.warn('%c Conflict', this.logFormat, error.message);
            this.messageService.add({ severity: 'warn', detail: error.error.message });
            break;
          case HttpError.InternalServerError:
            console.error('%c Internal server error', this.logFormat, error.message);
            this.messageService.add({ severity: 'error', detail: 'An error occurred, please try again.' });
            break;
        }
      }

      throw error;
    }));
  }
}
